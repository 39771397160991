import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';

function QuestionComponent({ db }) {
  const [newProblem, setNewProblem] = useState('');
  const [newAnswer1, setNewAnswer1] = useState('');
  const [newAnswer2, setNewAnswer2] = useState('');
  const [newAnswer3, setNewAnswer3] = useState('');
  const [newAnswer4, setNewAnswer4] = useState('');
  const [newCorrectAnswer, setCorrectAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //Function to handle adding the question with answers
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if(newCorrectAnswer === '') {
      setLoading(false);
      setError('Please select a correct answer.');
      return;
    }

    try {
      //Combine the problem and answers into one Firestore document
      await addDoc(collection(db, 'Questions'), {
        problem: newProblem,
        answers: [newAnswer1, newAnswer2, newAnswer3, newAnswer4],  // Store answers as an array
        correctAnswer: newCorrectAnswer
      });

      // Clear input fields after successful submission
      setNewProblem('');
      setNewAnswer1('');
      setNewAnswer2('');
      setNewAnswer3('');
      setNewAnswer4('');
      setCorrectAnswer('');
      alert('Question added successfully!');
    } catch (err) {
      setError('Failed to add question: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  //Look at index of array and give the value
  const getCorrectAnswerText = () => {
    const answers = [newAnswer1, newAnswer2, newAnswer3, newAnswer4];
    return answers[parseInt(newCorrectAnswer)];
  };

  return (
    <div className="container">
      <div className="form-container">
        <h2>Add a New Question</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <h4>Question</h4>
            <label htmlFor="problem">Problem: </label>
            <input
              id="problemText"
              type="text"
              value={newProblem}
              onChange={(e) => setNewProblem(e.target.value)}
              required
            />
          </div>
          <h4>Answers:</h4>
          <div>
          <label htmlFor="answer1">Option 1: </label>
            <input
                id="answer1Text"
                type="text"
                value={newAnswer1}
                onChange={(e) => setNewAnswer1(e.target.value)}
                required
            />
          </div>
          <div>
          <label htmlFor="answer2">Option 2: </label>
            <input
                id="answer2Text"
                type="text"
                value={newAnswer2}
                onChange={(e) => setNewAnswer2(e.target.value)}
                required
            />
          </div>
          <div>
          <label htmlFor="answer3">Option 3: </label>
            <input
                id="answer3Text"
                type="text"
                value={newAnswer3}
                onChange={(e) => setNewAnswer3(e.target.value)}
                required
            />
          </div>
          <div>
          <label htmlFor="answer4">Option 4: </label>
            <input
                id="answer4Text"
                type="text"
                value={newAnswer4}
                onChange={(e) => setNewAnswer4(e.target.value)}
                required
            />
          </div>
          <button className="button2" type="submit" disabled={loading}>
            {loading ? 'Adding...' : 'Add Question'}
          </button>
        </form>
      </div>

      <div className="answers-container">
        <h2>Preview</h2>
        <h3>{newProblem}</h3>
        <div>
          <input
            type="radio"
            id="correctAnswer1"
            name="correctAnswer"
            value="0"
            onChange={(e) => setCorrectAnswer(e.target.value)}
          />
          <label htmlFor="correctAnswer">a. {newAnswer1}</label>
        </div>
        <div>
          <input
            type="radio"
            id="correctAnswer2"
            name="correctAnswer"
            value="1"
            onChange={(e) => setCorrectAnswer(e.target.value)}
          />
          <label htmlFor="correctAnswer">b. {newAnswer2}</label>
        </div>
        <div>
          <input
            type="radio"
            id="correctAnswer3"
            name="correctAnswer"
            value="2"
            onChange={(e) => setCorrectAnswer(e.target.value)}
          />
          <label htmlFor="correctAnswer">c. {newAnswer3}</label>
        </div>
        <div>
          <input
            type="radio"
            id="correctAnswer4"
            name="correctAnswer"
            value="3"
            onChange={(e) => setCorrectAnswer(e.target.value)}
          />
          <label htmlFor="correctAnswer">d. {newAnswer4}</label>
        </div>
        <p>Correct Answer: {getCorrectAnswerText()}</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display any error messages */}
      </div>
    </div>
  );
}

export default QuestionComponent;
