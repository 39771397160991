import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

//Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAL41MjROS4GX_w1-14K95aQ_U1tMKAGe4",
    authDomain: "daily-dose-d5868.firebaseapp.com",
    projectId: "daily-dose-d5868",
    storageBucket: "daily-dose-d5868.appspot.com",
    messagingSenderId: "90535038506",
    appId: "1:90535038506:web:19b8fd89a5ed882d4f3d53"
  };
  
//Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
  
//Initialize Firestore
const db = getFirestore(app);
export { db };