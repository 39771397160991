class Narrative {
    constructor() {
        this.text = "";
        this.ER = "";
        this.age = null;
        this.reference = "";
        this.gender = "";

        this.nurseReport = "";

        this.patientStatement = "";
        this.patientDenies = [];

        this.ambulatory = false;
        this.aAndO = 0;
        this.airway = false;
        this.respiratoryRate = 0;
        this.chestRise = false;
        this.pulseStrength = "";
        this.pulseRegularity = "";
        this.skinCondition = "";
        this.GCS = null;
        this.rhythm = "";
    }

    draftNarrative() {
        //Subjective
            this.text = `PT transport requested by ${this.ER} in response to a ${this.age}y/o ${this.gender} who is being transported to the main for ${this.reference}.`;
            this.text += `\n\n${this.ER} Nurse: ${this.nurseReport}`;
            this.text += `\n\nPatient: ${this.patientStatement}. Patient denies ${this.patientDenies.join(', ')}.`;

        //Objective
        this.text += "\n\n";
            //Ambulatory
            if(this.ambulatory === true) {
                this.text += "Ambulatory. ";
            }
            else
            {
                this.text += "PT is not able to ambulate on their own. ";
            }
            //A&O
            if(this.aAndO === 0) {
                this.text += "Confused and disoriented. ";
            }
            else
            {
                this.text += `Alert and Oriented x ${this.aAndO}. `;
            }
            //Airway
            if(this.airway === true) {
                this.text += "Airway patent. ";
            }
            else
            {
                this.text += "!!!EXPLAIN AIRWAY PATENCY!!! ";
            }
            //Resporitory Rate and Chest Rise
            if(this.chestRise === true) {
                this.text += `Breathing at a rate of ${this.respiratoryRate} with adequate chest rise and fall. `;
            }
            else
            {
                this.text += "!!!EXPLAIN WHY CHEST RISE AND FALL IS NOT ADEQUATE!!! ";
            }
            //Pulse
            if(this.pulseRegularity === true) {    
                this.text += `Pulse is ${this.pulseStrength} and regular. `;
            }
            else
            {
                this.text += `Pulse is ${this.pulseStrength} and irregular. `;
            }
            //Skin
            this.text += `Skin is ${this.skinCondition}. `;
            //GCS
            this.text += `GCS of ${this.GCS}. `;
            //Cardiac Rhythm
            this.text += `${this.rhythm} on the monitor. `;

        //Interventions
            this.text += "\n\n";
            this.text += "PT assisted from hospital bed to stretcher. Seatbelt straps fastened around PT. ";
            this.text += "Baseline vitals taken. PT rested comfortably on stretcher for duration of transport. ";
            this.text += "PT assisted from stretcher to hospital bed at main hospital.";

        return this.text;

    }
}

export default Narrative;