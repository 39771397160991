import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';


//Navigation Buttons

export function CreateAccountNavButton() {
    const navigate = useNavigate();
    return  (
      <button className="button1" onClick={() => navigate('/signup')}>
        Create Account
      </button>
    );
  }

  export function EditorNavButton() {
    const navigate = useNavigate();
    return  (
      <button className="button2" onClick={() => navigate('/Editor')}>
        Editor
      </button>
    );
  }

  export function QuestionBankNavButton() {
    const navigate = useNavigate();
    return  (
      <button className="button2" onClick={() => navigate('/QuestionBank')}>
        Question Bank
      </button>
    );
  }

  export function ProtocolsNavButton() {
    const navigate = useNavigate();
    return  (
      <button className="button2" onClick={() => navigate('/Protocols')}>
        Protocols
      </button>
    );
  }

  export function DevelopmentPlanNavButton() {
    const navigate = useNavigate();
    return  (
      <button className="button2" onClick={() => navigate('/DevelopmentPlan')}>
        Development Plan
      </button>
    );
  }

  export function DailyQuestionNavButton() {
    const navigate = useNavigate();
    return  (
      <button className="button2" onClick={() => navigate('/DailyQuestion')}>
        Daily Question
      </button>
    );
  }

  export function BackToLoginButton() {
    const navigate = useNavigate();
    return  (
      <button className="button2" onClick={() => navigate('/')}>
        Back
      </button>
    );
  }

  export function BackToHomeButton() {
    const navigate = useNavigate();
    const auth = getAuth();
    const user = auth.currentUser;
    return  (
      <button className="button2" onClick={() => navigate(`/Home/${user.uid}`)}>
        Back
      </button>
    );
  }