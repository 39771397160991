import React from "react";
import ProtocolsComponent from "../components/ProtocolsComponent";




export default function Protocols() {

    return (
      <div className="App">
          <div>
            <ProtocolsComponent />
          </div>
      </div>
    );
  }
