import React from "react";
import NarrativeComponent from "../components/NarrativeComponent";

export default function NarrativeAssistant() {

    return (
        <div>
            <NarrativeComponent />
        </div>
    );
  }
