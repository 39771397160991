import React from "react";
import { BackToHomeButton } from "../components/NavigationButtons";
import DailyQuestionComponent from "../components/DailyQuestionComponent";



export default function DailyQuestion() {

    return (
      <div className="App">
        <h1 className="header">Questions</h1>
          <div>
            <DailyQuestionComponent />
          </div>
          <div>
            <BackToHomeButton />
          </div>
      </div>
    );
  }
