import React, { useState } from 'react';
import Narrative from '../models/Narrative';

const NarrativeComponent = () => {

    const [narrative, setNarrative] = useState(new Narrative());
    const [showReport, setShowReport] = useState(false);

    const updateNarrative = (key, value) => {
        setNarrative(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const options = [
        { id: 1, label: 'Cough'},
        { id: 2, label: 'Nausea'},
        { id: 3, label: 'Vomitting'},
        { id: 4, label: 'Dizziness'},
        { id: 5, label: 'Weakness'},
        { id: 6, label: 'SoB'},
        { id: 7, label: 'Chest PN'}
    ]

    const handleCheckBoxChange = (event, label) => {
        if(event.target.checked) {
            setNarrative(prevNarrative => ({
                ...prevNarrative,
                patientDenies: [...prevNarrative.patientDenies, label]
            }))
        }
        else
        {
            setNarrative(prevNarrative => ({
                ...prevNarrative,
                patientDenies: prevNarrative.patientDenies.filter(item => item !== label)
            }))
        }
    }

    const handleRadioChangeAmbulatory = (event) => {
        const value = event.target.value === 'yes'; 
        setNarrative(prevNarrative => ({
            ...prevNarrative,
            ambulatory: value
        }));
    };

    const handleRadioChangeAirway = (event) => {
        const value = event.target.value === 'yes'; 
        setNarrative(prevNarrative => ({
            ...prevNarrative,
            airway: value
        }));
    };

    const handleRadioChangeChestRise = (event) => {
        const value = event.target.value === 'yes'; 
        setNarrative(prevNarrative => ({
            ...prevNarrative,
            chestRise: value
        }));
    };

    const handleRadioChangePulse = (event) => {
        const value = event.target.value === 'yes'; 
        setNarrative(prevNarrative => ({
            ...prevNarrative,
            pulseRegularity: value
        }));
    };

    const generateReport = () => {
        const updatedNarrative = new Narrative(); // Create a new instance
        Object.assign(updatedNarrative, narrative); // Copy the current narrative state
    
        updatedNarrative.draftNarrative(); // Generate the narrative text
    
        setNarrative({ ...updatedNarrative });  // Set the state with the new narrative including text
        setShowReport(true);  // Show the report
    };
    

    return (
        <div>
        <h1 className="header">Narrative Assistant</h1>
            <div>
                <h2>Subjective</h2>
                <div className="secondary_view">
                    <h3 >Dispatch</h3>
                        <p>What ER was this patient at: </p>
                            <input
                                id="problemText"
                                type="text"
                                value={narrative.ER}
                                onChange={(e) => updateNarrative('ER', e.target.value)}
                                required
                            />
                        <p>What is the reference: </p>
                            <input
                                id="problemText"
                                type="text"
                                value={narrative.reference}
                                onChange={(e) => updateNarrative('reference', e.target.value)}
                                required
                            />
                        <p>Patient age? </p>
                            <input
                                id="problemText"
                                type="text"
                                value={narrative.age}
                                onChange={(e) => updateNarrative('age', Number(e.target.value))}
                                required
                            />
                        <p>Patient gender? </p>
                            <select
                                id="genderSelect"
                                value={narrative.gender}
                                onChange={(e) => updateNarrative('gender', e.target.value)}
                                required
                            >
                                <option value="">Select gender</option> {/* Default placeholder option */}
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                    <h3>Nurse Report</h3>
                        <textarea
                            id="nurseReport"
                            value={narrative.nurseReport}
                            onChange={(e) => updateNarrative('nurseReport', e.target.value)}
                            required
                        />
                    <h3>Patient Statments</h3>
                        <textarea
                            id="patientStatement"
                            value={narrative.patientStatement}
                            onChange={(e) => updateNarrative('patientStatement', e.target.value)}
                            required
                        />
                        <h4>Patient denies: </h4>
                            {options.map(option => (
                                <div>
                                    <input
                                        type="checkbox"
                                        id={option.id}
                                        onChange={(e) => handleCheckBoxChange(e, option.label)}
                                    />
                                    <label htmlFor={option.id} >{option.label} </label>
                                </div>
                            ))}
                </div>
            <div>
                <h2>Objective</h2>
                <div className="secondary_view">
                    <p>Ambulatory? </p>
                        <div className="radio-group">
                            <input
                                type="radio"
                                id="ambulatoryYes"
                                name="ambulatory"
                                value="yes"
                                checked={narrative.ambulatory === true}
                                onChange={handleRadioChangeAmbulatory}
                            />
                            <label htmlFor="ambulatoryYes">Yes</label>

                            <input
                                type="radio"
                                id="ambulatoryNo"
                                name="ambulatory"
                                value="no"
                                checked={narrative.ambulatory === false}
                                onChange={handleRadioChangeAmbulatory}
                            />
                            <label htmlFor="ambulatoryNo">No</label>
                        </div>
                    <p>Alert and Oriented? </p>
                            <select
                                id="A&OSelect"
                                value={narrative.aAndO}
                                onChange={(e) => updateNarrative('aAndO', e.target.value)}
                                required
                                >
                                <option value="0">0</option> {/* Default option */}
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                    <p>Airway patent? </p>
                        <div className="radio-group">
                            <input
                                type="radio"
                                id="airwayYes"
                                name="airway"
                                value="yes"
                                checked={narrative.airway === true}
                                onChange={handleRadioChangeAirway}
                            />
                            <label htmlFor="airwayYes">Yes</label>

                            <input
                                type="radio"
                                id="airwayNo"
                                name="airway"
                                value="no"
                                checked={narrative.airway === false}
                                onChange={handleRadioChangeAirway}
                            />
                            <label htmlFor="airwayNo">No</label>
                        </div>
                    <p>Breathing rate: </p>
                        <input
                            id="breathingText"
                            type="text"
                            value={narrative.respiratoryRate}
                            onChange={(e) => updateNarrative('respiratoryRate', Number(e.target.value))}
                            required
                        />
                    <p>Adequate chest rise and fall? </p>
                        <div className="radio-group">
                            <input
                                type="radio"
                                id="chestRiseYes"
                                name="chestRise"
                                value="yes"
                                checked={narrative.chestRise === true}
                                onChange={handleRadioChangeChestRise}
                            />
                            <label htmlFor="chestRiseYes">Yes</label>

                            <input
                                type="radio"
                                id="chestRiseNo"
                                name="chestRise"
                                value="no"
                                checked={narrative.chestRise === false}
                                onChange={handleRadioChangeChestRise}
                            />
                            <label htmlFor="chestRiseNo">No</label>
                        </div>
                    <p>Pulse strength: </p>
                        <select
                            id="pulseStrengthSelect"
                            value={narrative.pulseStrength}
                            onChange={(e) => updateNarrative('pulseStrength', e.target.value)}
                            required
                        >
                            <option value="Strong">Strong</option> 
                            <option value="Weak">Weak</option>
                        </select>
                    <p>Pulse regular? </p>
                    <div className="radio-group">
                            <input
                                type="radio"
                                id="pulseRegularYes"
                                name="pulseRegular"
                                value="yes"
                                checked={narrative.pulseRegularity === true}
                                onChange={handleRadioChangePulse}
                            />
                            <label htmlFor="pulseRegularYes">Yes</label>

                            <input
                                type="radio"
                                id="pulseRegularNo"
                                name="pulseRegular"
                                value="no"
                                checked={narrative.pulseRegularity === false}
                                onChange={handleRadioChangePulse}
                            />
                            <label htmlFor="pulseRegularNo">No</label>
                        </div>
                    <p>Skin condition: </p>
                        <select
                            id="skinSelect"
                            value={narrative.skinCondition}
                            onChange={(e) => updateNarrative('skinCondition', e.target.value)}
                            required
                            >
                            <option value="Warm and Dry">Warm and Dry</option> {/* Default option */}
                            <option value="Cool and Clammy">Cool and Clammy</option>
                            <option value="Pale and Diaphoretic">Pale and Diaphoretic</option>
                            <option value="Flushed and Dry">Flushed and Dry</option>
                            <option value="Cyanotic">Cyanotic</option>
                            <option value="Jaundiced">Jaundiced</option>
                            <option value="Mottled">Mottled</option>
                        </select>
                    <p>GCS: </p>
                        <input
                                id="GCSText"
                                type="text"
                                value={narrative.GCS}
                                onChange={(e) => updateNarrative('GCS', Number(e.target.value))}
                                required
                        />
                    <p>Cardiac Rhythm: </p>
                        <input
                            id="problemText"
                            type="text"
                            value={narrative.rhythm}
                            onChange={(e) => updateNarrative('rhythm', e.target.value)}
                            required
                        />
                </div>
            </div>
        </div>
        <div>
            <p></p>
            <p></p>
            <p></p>
        </div>
        <div className='secondary_view'> 
            <button onClick={generateReport} className='button2'>Draft Narrative</button>
            {showReport && (
                <div className='answers-container'>
                    <h1>Report</h1>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{narrative.text}</p>
                </div>
            )} 
        </div> 
        </div>

    );
};

export default NarrativeComponent;
