import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

function QuestionList({ db }) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    // Real-time listener for the 'Questions' collection
    const unsubscribe = onSnapshot(collection(db, 'Questions'), (snapshot) => {
      setQuestions(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    });

    // Cleanup subscription on unmount
    return unsubscribe;
  }, [db]);

  return (
    <div className="question-container">
      {questions.map((question) => (
        <div key={question.id} className="question-box">
          <p className="question-text">{question.problem}</p>
          <div className="answer-box">
            <ol className="answer-list">
              {question.answers.map((answer, index) => (
                <li key={index} className="answer-item">{answer}</li>
              ))}
            </ol>
            <p className="correct-answer">Correct Answer: {question.correctAnswer}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default QuestionList;
