import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../components/firebaseConfig"; // Your Firestore setup

function DailyQuestionComponent() {
  const [question, setQuestion] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadDailyQuestion = async () => {
      try {
        // Fetch the daily question from the "DailyQuestion/current" document
        const docRef = doc(db, 'DailyQuestion', 'current');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setQuestion(docSnap.data()); // Load the question data
        } else {
          setError('No daily question found');
        }
      } catch (err) {
        setError('Failed to load daily question: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    loadDailyQuestion();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      {question && (
        <>
          <h2>{question.problem}</h2> {/* Display the question/problem */}
          <ul>
            {question.answers && question.answers.map((answer, index) => (
              <li key={index}>{String.fromCharCode(97 + index)}. {answer}</li> 
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default DailyQuestionComponent;
