import React from "react";
import { EditorNavButton, QuestionBankNavButton, BackToLoginButton, ProtocolsNavButton, DevelopmentPlanNavButton, DailyQuestionNavButton } from "../components/NavigationButtons";

function Home() {
  
    return (
      <div className="App">
        <h1 className="header">Home</h1>
        <h2>Admin Menu</h2>
          <header>
            <EditorNavButton />
            <QuestionBankNavButton />
          </header>
          <header>
            <ProtocolsNavButton />
            <DevelopmentPlanNavButton />
          </header>
          <header>
            <DailyQuestionNavButton />
          </header>
          <header>
            <BackToLoginButton />
          </header>
      </div>
    );
  }
  

  
  export default Home;
  